import React from 'react';
import { Router } from '@reach/router';
import { PrivateRoute } from '../../../components/auth/authorization/private-route';
import NotFoundPage from '../../404';
import { BaseLayout } from '../../../components/layout/base/base-layout';
import { ProductPreview } from '../../../admin/product/product-preview';
import { SEO } from '../../../components/layout/seo';

const ProductPreviewPage = () => (
  <BaseLayout>
    <SEO title="Product Preview" />
    <Router basepath="/product/preview">
      <NotFoundPage path="/" />
      <PrivateRoute
        isStaff
        permissions={['MANAGE_PRODUCTS']}
        path="/:id"
        Component={ProductPreview}
      />
    </Router>
  </BaseLayout>
);

export default ProductPreviewPage;
