import React from 'react';

import { Button, Result } from 'antd';
import { Link } from 'gatsby';
import { BaseLayout } from '../components/layout/base/base-layout';
import { SEO } from '../components/layout/seo';
import isBrowser from '../apollo/is-browser';
import { CenteredSection } from '../components/layout/sections/centered/centered-section';

const NotFoundPage = () => isBrowser() && (
  <BaseLayout>
    <SEO title="404: Not found" />
    <CenteredSection>
      <Result
        status="404"
        title="404"
        subTitle="Oops! The link you followed does not lead anywhere."
        extra={[<Link to="/"><Button size="large" type="secondary">Home</Button></Link>,
          <Link to="/search"><Button size="large" type="primary" /></Link>]}
      />
    </CenteredSection>
  </BaseLayout>
);

export default NotFoundPage;
