import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Alert, Divider, Result, Spin,
} from 'antd';
import { QUERY_PRODUCT_PREVIEW } from '../../graphql/queries/queryProductPreview';
import { ProductTemplate } from '../../templates/product/product-template';
import { CenteredSection } from '../../components/layout/sections/centered/centered-section';

export const ProductPreview = ({ id }) => {
  const {
    data,
    loading,
  } = useQuery(QUERY_PRODUCT_PREVIEW, {
    variables: { id },
    skip: !id,
  });

  if (!id) {
    return (
      <CenteredSection>
        <Result status="404" title="No matching product found" />
      </CenteredSection>
    );
  }

  if (loading) {
    return (
      <CenteredSection>
        <Spin />
      </CenteredSection>
    );
  }

  return (
    <>
      <Alert type="info" showIcon message="This shows how the product will appear once built" />
      <Divider />
      <ProductTemplate pageContext={data.product} />
    </>
  );
};

ProductPreview.defaultProps = {
  id: '',
};

ProductPreview.propTypes = {
  id: PropTypes.string,
};
