import { gql } from '@apollo/client';

export const QUERY_PRODUCT_PREVIEW = gql`
    query Product($id: ID!) {
        product (id: $id) {
            id
            seoTitle
            seoDescription
            name
            slug
            defaultVariant {
                id
            }
            description
            productType {
                name
                hasVariants
                slug
            }
            category {
                name
                slug
            }
            location {
                geography
                address {
                    city
                    postalCode
                }
            }
            metadata {
                key
                value
            }
            attributes {
                attribute {
                    name
                    inputType
                }
                values {
                    name
                }
            }
            variants {
                id
                name
                sku
                attributes {
                    attribute {
                        name
                        inputType
                    }
                    values {
                        name
                    }
                }
                images {
                    url (size: 500)
                    alt
                }
            }
            images {
                url (size: 2000)
                alt
            }
        }
    }
`;
